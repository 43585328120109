import React, { useEffect } from 'react';
import { Breadcrumb, SimpleCard } from '@gull';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Dropdown, Badge } from "react-bootstrap";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import service from '../contractorService';
import moment from 'moment';
import { paginationOptions } from 'app/utils/constants';
import { useAuthState } from 'app/auth';
import { StatusClass } from 'app/utils/constants';
import { formatDateTime12 } from 'app/utils/time';
import { MdMoreVert} from "react-icons/md";
import swal from "sweetalert2";
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { ReloadButton } from 'app/widgets';
import { useSocket } from 'app/socket.io';
import { RefreshType, refreshOnNotification } from 'app/notification'

export const AccessRegister = () => {

  const { SearchBar, ClearSearchButton } = Search;
  const authState = useAuthState();
  const [projects, setProjects] = React.useState([]);
  const spinner = useSpinner();
  const socket = useSocket()

  const getAccessRegister = React.useCallback(() => {
      
    if (authState.isAuthenticated) {
      spinner.show()

      service.getAccessRegister(authState.user.id)
      .then(result => {
        if (result.error) {
          NotificationManager.error(result.message, 'Delete Access', 3000);      
        }
        else {
          setProjects(result); 
        }
      })
      .catch(e => NotificationManager.error('Something went wrong.', 'Server Connection', 3000))
      .finally(() => spinner.hide())  
    }
  })

  const projectColumns = [
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
      sort: true,
      headerStyle: () => ({ width: '30%' }),
   },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'tenderRequiredBy',
      text: 'Due In',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => formatDateTime12(row.tenderRequiredBy))
    },
    {
      dataField: 'Time Remaining',
      text: 'Time Remaining',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        let retVal = '';
        if (row.tenderRequiredBy) {
          const hours = moment(row.tenderRequiredBy).diff(moment(), 'hours'); 
          if (hours && hours > 24) {
            retVal = <span className="ul-widget3-status text-success t-font-bolder">{ Math.floor(hours/24) } days</span>
          }
          else {
            retVal = <span className="ul-widget3-status text-warning t-font-bolder">{ hours } hours</span>
          }
        }
        return retVal;
      })
    },
    {
      dataField: 'Access',
      text: 'Access',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.access.toUpperCase()]}>{row.access}</Badge>
      })
    },
    {
      dataField: 'comments',
      text: 'Comments',
      headerAlign: 'center',
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'Action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '60px' }),
      formatter: ((cell, row) => { 
        return <Dropdown>
          <Dropdown.Toggle as="span" className="cursor-pointer toggle-hidden">
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>          
            <Dropdown.Item
              onClick={() => {
                swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to access this project!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, withdraw!",
                    cancelButtonText: "No"
                  })
                  .then(result => {
                    if (result.value) {
                      deleteAccess(row);
                    } 
                  });
              }}
            >
              <i className="nav-icon i-Close-Window text-danger font-weight-bold mr-2"></i>
              Withdraw
            </Dropdown.Item>
          
          </Dropdown.Menu>
        </Dropdown>
      })
    },
  ];

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.CONTRACTOR_ACCESS, getAccessRegister)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, [])

  React.useEffect(() => {

    getAccessRegister()
  }, [authState.user]);

  const deleteAccess = (project) => {

    spinner.show()

    service.deleteAccess(authState.user.id, project.id)
    .then(result => {
      if (result.error) {
        NotificationManager.error(result.message, 'Delete Access', 3000);      
      }
      else {
        NotificationManager.warning('Project access was withdrawn', 'Delete Access', 3000);      
        getAccessRegister();    
      }
    })
    .catch(e => {
      NotificationManager.error('Something went wrong.', 'Server Connection', 3000)
    })
    .finally(() => spinner.hide())
  }

  return (
    <div>
      <Breadcrumb routeSegments={[{ name: 'Access Register' }]}></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>
            <ToolkitProvider
              keyField='id'
              data={projects}
              columns={projectColumns}
              search
              exportCSV
            >
              {props => (
                <>
                  <div className='d-flex justify-content-end align-items-center'>
                   
                    <span className='mb-2 mr-1'>Search:</span>
                    <SearchBar {...props.searchProps} className='mb-0 ' />
                    <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                    <ReloadButton onClick={getAccessRegister} />
                    <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    wrapperClasses="table-responsive"
                    pagination={paginationFactory({ ...paginationOptions, totalSize: projects.length })}
                    noDataIndication={'No records found'}
                    hover
                    condensed
                  />
                </>
              )}
            </ToolkitProvider>
          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
