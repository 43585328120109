import React, { useState, useEffect } from 'react';
import { SimpleCard } from '@gull';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Breadcrumb } from '@gull';
import userService from './userService';
import { ContactsWidget } from '.';
import { useAuthState } from 'app/auth';
import { addContact } from 'app/utils/common';
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';

export const UserContacts = () => {

  const [contacts, setContacts] = useState([]);
  const spinner = useSpinner();

  const getUserContacts = React.useCallback(() => {
      
    if (authState.isAuthenticated) {
      spinner.show()
      userService.getUserContacts(authState.user.id)
      .then(result => {
        if (result.error) {
          NotificationManager.error(result.message, 'Server Connection', 3000);      
        }
        else {
          const items = result.map((item, i) => { return {...item, index: i + 1 }});
          setContacts(items); 
        }
      })
      .catch(e => {
        NotificationManager.error('Something went wrong.', 'Server Connection', 3000);      
      }) 
      .finally(() => {
        spinner.hide()
      }) 
    }    
  })

  const authState = useAuthState();

  useEffect(() => {

    getUserContacts();
  }, [authState.user]);

  const updateContact = async contact => {

    spinner.show()

    try {
      const result = await userService.updateContact(authState.user.id, contact.id, contact);

      if (result && result.error) {
        NotificationManager.error('Update failed.', 'Contact', 3000);
      }
      else {
        NotificationManager.success('Update succeeded.', 'Contact', 3000);
        setContacts(result && result.contacts ? result.contacts : []);
      }
    }
    catch {
      NotificationManager.error('Update failed.', 'Contact', 3000);
    }
    finally {
      spinner.hide()
    }
  }

  const deleteContact = async contact => {

    spinner.show()

    try {
      const result = await userService.deleteContact(authState.user.id, contact.id);

      if (result && result.error) {
        NotificationManager.error('Update failed.', 'Contact', 3000);
      }
      else {
        NotificationManager.success('Update succeeded.', 'Contact', 3000);
        setContacts(result && result.contacts ? result.contacts : []);
      }
    }
    catch {
      NotificationManager.error('Update failed.', 'Contact', 3000);
    }
    finally {
      spinner.hide()
    }
  }

  return (
    <div>
      <Breadcrumb routeSegments={[{ name: 'My Contacts' }]}></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>
            <ContactsWidget contacts={contacts} addContact={(contact) => addContact(authState.user.id, contact)} updateContact={updateContact} deleteContact={deleteContact} refreshContacts={getUserContacts} />
          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
