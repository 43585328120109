import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import chatService from './chatService';
import ChatContactsWidget from './ChatContactsWidget';
import ChatWidget from './ChatWidget';
import { isMobile } from '@utils';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Breadcrumb } from '@gull';
import { useAuthState } from 'app/auth';
import { useSocket } from 'app/socket.io';
import { useParams, useHistory } from "react-router-dom";
import { handleOpenChat } from 'app/utils/common';
import { RefreshType, refreshOnNotification } from 'app/notification'

export const Chat = () => {
  
  let windowResizeListener;

  const socket = useSocket()
  const authState = useAuthState();
  const [contactList, setContactList] = useState([]);
  const [recentContactList, setRecentContactList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState();
  const [opponentUser, setOpponentUser] = useState();
  const [open, setOpen] = useState(true);
  const [mobile, setMobile] = useState(false);
  const bottomRef = useRef();
  const history = useHistory();
  const { opponentId } = useParams();

  const getUserChatContacts = useCallback(() => {

    if (authState.user?.id) {
      chatService.getUserChatContacts(authState.user.id)
      .then(contacts => {
  
        if (contacts.error) {
          return NotificationManager.error('Sorry, something went wrong.', 'Chat Contacts', 3000);
        }
        // set top 10 contacts as recent
        setRecentContactList(contacts.slice(0, 10));
      })
      .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) });  
    }
    else {
      return NotificationManager.error('Sorry, something went wrong.', 'Chat Contacts', 3000);
    }
  });

  useEffect(() => {

    if (newMessage) {
      setMessages([...messages, newMessage])
    }
  }, [newMessage]);

  useEffect(() => {

    if (opponentId && opponentId !== ':opponentId') {
      switchRoom(opponentId);
    }
  }, [opponentId]);

  useEffect(() => {

    const onChat$ = socket.onChat().subscribe(message => {
      setNewMessage(message)
    });

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {
      refreshOnNotification(notification.type, RefreshType.CHAT_RECENT_CONTACTS, getUserChatContacts)
    })

    if (authState.user && authState.isAuthenticated) {
    
      getUserChatContacts(authState.user.id);

      chatService.getUserContacts(authState.user.id).then(contacts => {

        if (contacts.error) {
          return NotificationManager.error('Sorry, something went wrong.', 'Contacts', 3000);
        }
        setContactList(contacts);
      });
    
      if (isMobile()) {
        setOpen(false);
        setMobile(true);
      }
 
      if (window)
        windowResizeListener = window.addEventListener('resize', e => {
          if (isMobile()) {
            setOpen(false);
            setMobile(true);
          }
          else {
            setOpen(true);
            setMobile(false);
          }
        });
    }

    return() => {
      onUserNotification$.unsubscribe()
      onChat$.unsubscribe()

      if (window) {
        window.removeEventListener('resize', windowResizeListener);
      }
    }
  }, [authState.user]);

  useEffect(() => {

    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {

    if (bottomRef.current) {
      bottomRef.current.scrollTop = 9999999999999;
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const switchRoom = async opponentId => {

    if (isMobile()) toggleSidenav();

    try {
      const opponent = await chatService.getUserContact(authState.user.id, opponentId)

      if (opponent.error) throw ''

      setOpponentUser(opponent);
      const messages = await chatService.getUserChat(authState.user.id, opponentId)
      
      if (messages.error) throw ''
      
      setMessages(messages);

      // reload recent contacts
      // getUserChatContacts(authState.user.id);

      chatService.setChatAsRead(authState.user.id, opponentId)
    }
    catch {
      return NotificationManager.error('Something went wrong.', 'Chat', 3000);
    }
  }

  const handleMessageSend = (message, files) => {

    const payload = {
      from: authState.user.id,
      to: opponentUser.id,
      message: message,
      files: files ?? null
    }

    socket.chat(payload);
    setMessages([...messages, payload]);
  };

  const handleContactSelected = opponentId => {

    handleOpenChat(authState, history, opponentId)
  }

  const toggleSidenav = () => setOpen(!open);

  return (<>
    <Breadcrumb routeSegments={[{ name: 'Chat' }]}></Breadcrumb>
    <Card className='chat-sidebar-container sidebar-container'>
      <ChatContactsWidget
        open={open}
        isMobile={mobile}
        toggleSidenav={toggleSidenav}
        contactList={contactList}
        recentContactList={recentContactList}
        handleContactSelected={handleContactSelected}
      ></ChatContactsWidget>
      <ChatWidget
        open={open}
        isMobile={mobile}
        toggleSidenav={toggleSidenav}
        messages={messages}
        currentUser={authState.user}
        opponentUser={opponentUser}
        // currentChatRoom={socket.chatRoom}
        handleMessageSend={handleMessageSend}
      ></ChatWidget>
    </Card>

    <NotificationContainer />
    </>);
}
