import React from 'react';
import { SimpleCard } from '@gull';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import adminService from '../adminService';
import { AdminNavigation } from '../adminRoutes';
import { paginationOptions, ProjectStatus, StatusClass } from 'app/utils/constants';
import { useHistory } from "react-router-dom";
import { MdMoreVert} from 'react-icons/md';
import { Dropdown, Badge } from 'react-bootstrap';
import { constructCompanyName, navigateTo } from 'app/utils/common';
import { useAuthState } from 'app/auth';
import { formatDate } from 'app/utils/time';
import { ProjectAction } from '../constants';
import { handleOpenChat } from 'app/utils/common';
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { ReloadButton } from 'app/widgets';

export const ProjectsWidget = ({ projectStatus }) => {

  const { SearchBar, ClearSearchButton } = Search;
  const [projects, setProjects] = React.useState([]);
  const authState = useAuthState();
  const history = useHistory();
  const spinner = useSpinner();

  const columns = [
    {
      dataField: 'projectName',
      text: 'Project',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <span className='text-primary cursor-pointer' onClick={() => actionProject(ProjectAction.VIEW_PROJECT, row)}>{row.projectName}</span>;
      })
    },
    {
      dataField: 'client',
      text: 'Client',
      headerAlign: 'center',
      sort: true,
      formatter: ((cell, row) => { 
        return <span className="text-primary cursor-pointer" onClick={() => actionProject(ProjectAction.VIEW_CLIENT, row)}>{constructCompanyName(row.contact)}</span>;
      })
    },
    {
      dataField: 'classification',
      text: 'Classification',
      headerAlign: 'center',
    },
    {
      dataField: 'sector',
      text: 'Sector',
      headerAlign: 'center',
    },
    {
      dataField: 'budget',
      text: 'Budget',
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'status',
      text: '',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => ({ width: '80px' }),
      formatter: ((cell, row) => { 
        return <Badge className={StatusClass[row.status.toUpperCase()]}>{row.status}</Badge>
      })
    },
    {
      dataField: 'revision',
      text: 'Rev',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '50px' })
    },
    {
      dataField: 'created',
      text: 'Added',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => { return { width: '90px' }},
      formatter: ((cell, row) => formatDate(row.created))
    },
    {
      dataField: 'tenderRequiredBy',
      text: 'Due',
      headerAlign: 'center',
      align: 'center',
      sort: true,
      headerStyle: () => { return { width: '90px' }},
      formatter: ((cell, row) => formatDate(row.tenderRequiredBy))
    },
    {
      dataField: 'ref',
      text: 'Ref',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => ({ width: '90px' }),
    },
    {
      dataField: 'Action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      headerStyle: () => { return { width: '60px' }},
      formatter: ((cell, row) => { 

        return <Dropdown onSelect={action => actionProject(action, row)}>
          <Dropdown.Toggle as='span' className='cursor-pointer toggle-hidden'>
            <MdMoreVert size={18}></MdMoreVert>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={ProjectAction.VIEW_PROJECT}><i className='i-ID-Card text-primary'> </i>Project Card</Dropdown.Item>
            {(row.status === ProjectStatus.REVISE || row.status === ProjectStatus.PENDING || row.status === ProjectStatus.REVISED) && <Dropdown.Item eventKey={ProjectAction.EDIT_PROJECT}><i className='i-Medal-2 text-danger'> </i>Approve Project</Dropdown.Item>}
            {row.status === ProjectStatus.TENDERING && <Dropdown.Item eventKey={ProjectAction.EDIT_PROJECT}><i className='i-Pen-2 text-danger'> </i>Edit Project</Dropdown.Item>}
            <Dropdown.Item eventKey={ProjectAction.CONTACT_CLIENT}><i className='i-Business-Man text-success'> </i>Contact Client</Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
      })
  }];

  const loadProjects = React.useCallback(() => {

    spinner.show()
      
    adminService.getProjects(authState.user.id, projectStatus)
    .then(result => {
      if (result.error) {
        NotificationManager.error(result.message, 'Server Connection', 3000);      
      }
      else {
        const items = result.map((item, i) => { return {...item, index: i + 1 }});
        setProjects(items); 
      }
    })
    .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) })
    .finally(() => spinner.hide())
})

  React.useEffect(() => {
  
    loadProjects(projectStatus);
  }, [projectStatus]);

  const actionProject = (action, project) => {

    switch(action) {

      case ProjectAction.VIEW_PROJECT:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.PROJECT_CARD, project: project });

      case ProjectAction.VIEW_CLIENT:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.CLIENT_CARD, project: project });
  
      case ProjectAction.EDIT_PROJECT:
        return navigateTo({ history: history, navigation: AdminNavigation, pathname: AdminNavigation.APPROVE_PROJECT, project: project });

      case ProjectAction.CONTACT_CLIENT:
        return handleOpenChat(authState, history, project.clientId);
    }
  }

  return (
    <div>
      <div className='row'>
          <div className='col-md-12'>
            <SimpleCard>
              <ToolkitProvider
                keyField='id'
                data={projects}
                columns={columns}
                search
                exportCSV
              >
                {props => (
                  <>
                    <div className='d-flex justify-content-end align-items-center'>
                    
                      <span className='mb-2 mr-1'>Search:</span>
                      <SearchBar {...props.searchProps} className='mb-0 ' />
                      <ClearSearchButton { ...props.searchProps } className='btn btn-light mb-2 mr-1 ml-1' />
                      <ReloadButton onClick={loadProjects} />
                      <button className='btn btn-outline-success mb-2 mr-1' onClick={_ => props.csvProps.onExport()}>Export CSV</button>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      wrapperClasses="table-responsive"
                      pagination={paginationFactory({ ...paginationOptions, totalSize: projects.length })}
                      noDataIndication={'No projects found'}
                      condensed
                    />
                  </>
                )}
              </ToolkitProvider>

            </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
}
