import React, { useState } from "react";
import Scrollbar from "react-perfect-scrollbar";
import { ProfileImageWidget } from 'app/user';

const ChatContactsWidget = ({
  open,
  contactList = [],
  recentContactList = [],
  handleContactSelected,
  toggleSidenav
}) => {
  const [query, setQuery] = useState("");

  return (
    <div className="chat-sidebar-wrap sidebar" style={{ left: !open ? "-260px" : 0 }}>
      <div className="border-right">
        <div className="pt-2 pb-2 pl-3 pr-3 d-flex align-items-center o-hidden box-shadow-1 chat-topbar">
          <span className="link-icon d-md-none" onClick={toggleSidenav}>
            <i className="icon-regular ml-0 mr-3 i-Left"></i>
          </span>
          <div className="form-group m-0 flex-grow-1">
            <input
              type="text"
              className="form-control form-control"
              id="search"
              placeholder="Search contacts"
              value={query}
              onChange={({ target: { value } }) => setQuery(value)}
            />
          </div>
        </div>

        <Scrollbar className="contacts-scrollable">
          <div className="mt-4 pb-2 pl-3 pr-3 font-weight-bold text-muted border-bottom">
            Recent
          </div>

          {recentContactList && recentContactList.filter(user => {
              const filter = query.toLocaleLowerCase()
              const found  = user?.firstName?.toLocaleLowerCase().match(filter)
              || user?.lastName?.toLocaleLowerCase().match(filter)
              || user?.businessName?.toLocaleLowerCase().match(filter)
              return found
            })
            .map(user => (
              <div
                key={user.id}
                onClick={() => handleContactSelected(user.id)}
                className={`p-3 d-flex align-items-center justify-content-between border-bottom ${user.unreadCount === 0 ? `contact ${user.online ? 'online' : ''}` : 'cursor-pointer'}`}
              >
                <div className='d-flex align-items-center'>
                  <ProfileImageWidget profileImage={user.profileImage} userGroup={user.userGroup} className="avatar-sm rounded-circle mr-3" alt="" />
                  <div>
                    <h6 className="m-0">{`${user.firstName} ${user.lastName}`}</h6>
                    <span className="text-muted text-10 text-capitalize">
                      {/* {`${user.role}, ${user.businessName}`} */}
                      {user.role}
                    </span>
                  </div>
                </div>
                { user.unreadCount > 0 && <span className={`badge bg-${user.online ? 'success' : 'light'} text-white badge-pill`}>{user.unreadCount}</span>}
              </div>
            ))}

          <div className="mt-3 pb-2 pl-3 pr-3 font-weight-bold text-muted border-bottom">
            Contacts
          </div>
          {contactList
            .filter(user =>
              user?.firstName?.toLocaleLowerCase().match(query.toLocaleLowerCase()) || 
              user?.lastName?.toLocaleLowerCase().match(query.toLocaleLowerCase()) || 
              user?.businessName?.toLocaleLowerCase().match(query.toLocaleLowerCase())
            )
            .map(user => (
              <div
                key={user.id}
                onClick={() => handleContactSelected(user.id)}
                className={`p-3 d-flex border-bottom align-items-center contact ${user.online ? 'online' : ''}`}
              >
                <ProfileImageWidget profileImage={user.profileImage} userGroup={user.userGroup} className="avatar-sm rounded-circle mr-3" alt="" />
                <div>
                  <h6 className="m-0">{`${user.firstName} ${user.lastName}`}</h6>
                  <span className="text-muted text-10 text-capitalize">
                    {user.role}
                  </span>
                </div>
              </div>
            ))}
        </Scrollbar>
      </div>
    </div>
  );
};

export default ChatContactsWidget;
