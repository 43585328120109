import React, { useState, useEffect } from 'react';
import { SimpleCard } from '@gull';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Breadcrumb } from '@gull';
import service from '../contractorService';
import { useAuthState } from 'app/auth';
import { AwardRegisterWidget } from '.';
import { ContractorNavigation } from '../contractorRoutes';
import { withdrawBid, acceptAward, declineAward } from '../functions';
import { BidTab, BidAction } from 'app/utils/constants';
import { IwtbModal } from 'app/widgets';
import { handleOpenChat, navigateTo } from 'app/utils/common';
import { useHistory } from "react-router-dom";
import { useConfiguration } from 'app/configuration/ConfigurationContext';
import { useSpinner } from 'app/IwtbLayout/SharedComponents/spinner/SpinnerContext';
import { useSocket } from 'app/socket.io';
import { RefreshType, refreshOnNotification } from 'app/notification'

export const Awards = () => {

  const authState = useAuthState();
  const [awards, setAwards] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState('')
  const history = useHistory();
  const config = useConfiguration()
  const spinner = useSpinner();
  const socket = useSocket()

  const getContractorAwards = React.useCallback(() => {
      
    if (authState.isAuthenticated) {

      spinner.show()

      service.getContractorAwards(authState.user.id)
      .then(results => {
        if (results.error) {
          return NotificationManager.error(results.message, 'Server Connection', 3000);      
        }
        else {
          setAwards(results); 
        }
      })
      .catch(e => { NotificationManager.error('Something went wrong.', 'Server Connection', 3000) })
      .finally(() => spinner.hide())
    }
  })

  useEffect(() => {

    const onUserNotification$ = socket.onUserNotification().subscribe(notification => {

      refreshOnNotification(notification.type, RefreshType.CONTRACTOR_AWARDS, getContractorAwards)
    })
    return() => {
        onUserNotification$.unsubscribe()
    }
  }, [])

  React.useEffect(() => {

    getContractorAwards();
  }, [authState.user]);

  const handleWithdrawBid = async (projectId, bidId) => {

    try {
      await withdrawBid(authState.user.id, projectId, bidId);
      getContractorAwards();
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Withdraw Bid', 3000);
    }
  }

  const handleAcceptAward = async (projectId, bidId) => {
    
    try {
      await acceptAward(authState.user.id, projectId, bidId);
      getContractorAwards();
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Accept Award', 3000);
    }
  }

  const handleDeclineAward = async (projectId, bidId) => {
    
    try {
      await declineAward(authState.user.id, projectId, bidId);
      getContractorAwards();
    }
    catch {
      NotificationManager.error('Sorry, something went wrong.', 'Decline Award', 3000);
    }
  }

  const handleBidAction = (bid, action) => {

    switch(action) {
  
      case BidAction.CONTACT_CLIENT:
        return handleOpenChat(authState, history, bid.clientId);

      case BidAction.VIEW_COMMENTS:
        setComments(bid.comments)
        setShowComments(true)
        return 

      case BidAction.VIEW_SOW:
        return navigateTo({ history: history, navigation: ContractorNavigation, pathname: ContractorNavigation.BID_CARD, bid: bid, tab: BidTab.SOW });

      case BidAction.VIEW_BID:
        return navigateTo({ history: history, navigation: ContractorNavigation, pathname: ContractorNavigation.BID_CARD, bid: bid, tab: BidTab.DETAILS });

      case BidAction.WITHDRAW:
        return handleWithdrawBid(bid.projectId, bid.bidId);

      case BidAction.ACCEPT_AWARD:
        return handleAcceptAward(bid.projectId, bid.bidId);
    
      case BidAction.DECLINE_AWARD:
        return handleDeclineAward(bid.projectId, bid.bidId);
    }
  }

  return (
    <div>
      <Breadcrumb routeSegments={[{ name: 'My Awarded Bids' }]}></Breadcrumb>

      <div className='row'>
          <div className='col-md-12'>

          <SimpleCard>
            <AwardRegisterWidget awardRegister={awards} handleBidAction={handleBidAction} refreshAwards={getContractorAwards} />
          </SimpleCard>
        </div>
      </div>

      <NotificationContainer />
      <IwtbModal show={showComments} title='Comments' message={comments} handleClose={() => setShowComments(false)} />
    </div>
  );
}
